import { Icon } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { TAppIconProps } from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';
import EIdImage from './e-id.svg';

export const EId = ({ qa = 'e-id-icon', ...rest }: TAppIconProps) => {
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, { keyPrefix: 'icons' });
  const title = t('e-id');
  return (
    <Icon
      data-qa={qa}
      aria-label={title}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      {...rest}
      fill='none'
      sx={{
        image: {
          h: 'full',
          w: 'auto',
        },
      }}
    >
      <title>{title}</title>
      <image x='0' y='0' width='206' height='190' xlinkHref={EIdImage} />
    </Icon>
  );
};
