import { Box } from '@chakra-ui/react';
import { AppButtonEIdasComponent, AppButtonEIdComponent, AppButtonItsMe } from '@core';
import { SIGN_IN_INSTANCES } from '@features/parking-card-country-code';
import { useMemo } from 'react';

type TParkingCardCountryCodeBelgianSignInButtonsProps = {
  setIsLoading: (isTrue: boolean) => void;
};

export const ParkingCardCountryCodeBelgianSignInButtons = ({
  setIsLoading,
}: TParkingCardCountryCodeBelgianSignInButtonsProps) => {
  const supportedSignInInstances: string[] = useMemo(() => {
    return (HP.SUPPORTED_LOGIN_METHODS ?? '').split(' ') ?? [];
  }, []);

  const redirectToSignInInstance = (instance: string) => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.pathname = `/api/auth/login/${instance}`;
    setIsLoading(true);
    window.location.href = url.toString();
  };

  return (
    <Box display={'flex'} flexWrap={'wrap'} gap={4} my={4} maxWidth={{ sm: '360px' }}>
      {/*{supportedSignInInstances.includes(SIGN_IN_INSTANCES.ITS_ME) && (*/}
      <AppButtonItsMe onClick={() => redirectToSignInInstance(SIGN_IN_INSTANCES.ITS_ME)} />
      {/*)}*/}
      {supportedSignInInstances.includes(SIGN_IN_INSTANCES.E_ID) && (
        <AppButtonEIdComponent onClick={() => redirectToSignInInstance(SIGN_IN_INSTANCES.E_ID)} />
      )}
      {supportedSignInInstances.includes(SIGN_IN_INSTANCES.E_IDAS) && (
        <AppButtonEIdasComponent
          onClick={() => redirectToSignInInstance(SIGN_IN_INSTANCES.E_IDAS)}
        />
      )}
    </Box>
  );
};
