import { Box, Text, useMultiStyleConfig } from '@chakra-ui/react';
import { TCountry } from '@codeer/handypark-common';
import { COMPONENT_NAMES } from '@codeer/handypark-web-common';
import { AppFlagIcon } from '@core';
import flags from 'country-flag-icons/react/3x2';
import { ReactNode } from 'react';

export type TCountryTemplateProps = Pick<TCountry, 'iso2Code'> & {
  value: string;
  label: ReactNode;
};

export const CountryRadioButtonTemplate = ({ label, iso2Code }: TCountryTemplateProps) => {
  const styles = useMultiStyleConfig(COMPONENT_NAMES.RADIO_BUTTON);

  return (
    <Box
      display={'flex'}
      flexWrap={'wrap'}
      justifyContent={'space-between'}
      alignItems={'flex-start'}
      gap={4}
    >
      <Text textStyle={'label'} sx={styles.title}>
        {label}
      </Text>
      <AppFlagIcon country={iso2Code as keyof typeof flags} alignSelf={'center'} />
    </Box>
  );
};
