import { AppComponentSwitch } from '@codeer/handypark-web-common';
import {
  PARKING_CARD_COUNTRY_CODE_STEPS,
  parkingCardCountryCodeStepAtom,
} from '@features/parking-card-country-code';
import { useAtomValue } from 'jotai';
import { lazy } from 'react';

const ParkingCardCountryCode = lazy(() =>
  import('@features/parking-card-country-code').then(module => ({
    default: module.ParkingCardCountryCode,
  })),
);

const ParkingCardCountryCodeForeign = lazy(() =>
  import('@features/parking-card-country-code').then(module => ({
    default: module.ParkingCardCountryCodeForeign,
  })),
);

export const ParkingCardCountryCodeStepSwitchComponent = () => {
  const parkingCardCountryCodeStep = useAtomValue(parkingCardCountryCodeStepAtom);

  return (
    <AppComponentSwitch activeState={parkingCardCountryCodeStep}>
      <ParkingCardCountryCode
        data-indexname={PARKING_CARD_COUNTRY_CODE_STEPS.PARKING_CARD_ORIGIN}
      />
      <ParkingCardCountryCodeForeign
        data-indexname={PARKING_CARD_COUNTRY_CODE_STEPS.PARKING_CARD_FOREIGN}
      />
    </AppComponentSwitch>
  );
};
