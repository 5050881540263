import { Icon } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppFlagIconProps } from '@core';
import flags from 'country-flag-icons/react/3x2';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export const AppFlagIcon: FC<AppFlagIconProps> = ({
  qa = 'app-flag-icon',
  country,
  ...rest
}): ReactElement => {
  const { t } = useTranslation(T_NAMESPACES.COUNTRIES);
  return (
    <Icon
      data-qa={`${qa}-${country}`}
      as={flags[country]}
      h={3}
      w={'auto'}
      aspectRatio={'3/2'}
      aria-label={t(country)}
      {...rest}
    />
  );
};
