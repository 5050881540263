import {
  PARKING_CARD_ORIGIN_OPTIONS,
  ParkingCardOriginRadioButtonTemplate,
  TParkingCardOriginTemplateProps,
} from '@/core';
import { Box } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  InputError,
  RadioButtons,
  TControlledRadioButtonsProps,
  transComponents,
} from '@codeer/handypark-web-common';
import { Controller, FieldValues } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

const keyPrefix = 'radio-buttons.parking-card-origin';
export const ParkingCardOriginRadioButtons = <T extends FieldValues>({
  qa = 'parking-card-radio-buttons',
  name,
  control,
  isRequired,
  showErrorMessage = true,
  ...rest
}: TControlledRadioButtonsProps<T>) => {
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, {
    keyPrefix,
  });

  const getStyledLabel = (type: string) => (
    <Trans
      i18nKey={`${keyPrefix}.options.${type}.text`}
      ns={T_NAMESPACES.COMPONENTS}
      components={transComponents}
    />
  );

  const cardOptions: TParkingCardOriginTemplateProps[] = [
    {
      value: PARKING_CARD_ORIGIN_OPTIONS.BELGIAN,
      label: getStyledLabel(PARKING_CARD_ORIGIN_OPTIONS.BELGIAN),
      description: t(`options.${PARKING_CARD_ORIGIN_OPTIONS.BELGIAN}.description`),
      ariaLabel: t(`options.${PARKING_CARD_ORIGIN_OPTIONS.BELGIAN}.aria-label`),
    },
    {
      value: PARKING_CARD_ORIGIN_OPTIONS.OTHER,
      label: getStyledLabel(PARKING_CARD_ORIGIN_OPTIONS.OTHER),
      ariaLabel: t(`options.${PARKING_CARD_ORIGIN_OPTIONS.OTHER}.aria-label`),
    },
  ];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <Box data-qa={qa} {...rest}>
            <RadioButtons
              required={isRequired}
              name={name}
              options={cardOptions}
              value={field.value}
              onChange={e => field.onChange(e)}
              renderItem={(option: TParkingCardOriginTemplateProps) => {
                return <ParkingCardOriginRadioButtonTemplate {...option} />;
              }}
              showErrorMessage={false}
            />
            <InputError show={showErrorMessage} fieldState={fieldState} />
          </Box>
        );
      }}
    />
  );
};
